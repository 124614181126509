import { MessageResponse, instanceOfMessageResponse } from '@jucy/rentals-api-client/rentals-api-v3/models/MessageResponse';
import { ResponseError } from '@jucy/rentals-api-client/rentals-api-v3/runtime';
import { asError } from './asError';

export const isResponseError = (error: unknown): error is ResponseError =>
    error instanceof ResponseError ||
    Boolean(typeof error === 'object' && error && 'name' in error && error.name === 'ResponseError' && 'response' in error);

export const isMessageResponse = (error: unknown): error is MessageResponse =>
    Boolean(error && typeof error === 'object' && instanceOfMessageResponse(error));

export const resolveRentalsApiErrorMessage = (error: unknown): string => {
    if (isResponseError(error)) {
        if (isMessageResponse(error.data)) {
            return error.data.message;
        }
        return error.message;
    }
    if (isMessageResponse(error)) {
        return error.message;
    }
    return asError(error).message || 'Something went wrong';
};

export const resolveRentalsApiError = (error: unknown): { message: string; type: string } => {
    if (isResponseError(error)) {
        if (isMessageResponse(error.data)) {
            return {
                type: `${error.data.statusCode || 400}`,
                message: error.data.message,
            };
        }
        return {
            type: '500',
            message: error.message,
        };
    }
    return { type: '400', message: asError(error).message || 'Something went wrong' };
};
