'use client';

import React from 'react';
import { useFormState } from '@jucy-ui/forms';
import { SxProps, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { ZoomSpinner } from '../../ZoomSpinner';
import { useSearchFormStore } from '../hooks';
import { jucySearchFormClasses } from '../lib';

export const SearchFormSubmitButton: React.FC<{ label?: string; disabled?: boolean; sx?: SxProps<Theme> }> = ({ label, disabled, sx }) => {
    const form = useFormState();
    const store = useSearchFormStore();

    return (
        <Button
            type="submit"
            name="search"
            disabled={Boolean(disabled || Object.keys(form.errors).filter((fieldName) => fieldName !== 'root').length || form.isSubmitting)}
            variant="contained"
            color="primary"
            size="extra-large"
            className={jucySearchFormClasses.submitButton}
            sx={sx}
        >
            {label || 'Search'} <ZoomSpinner show={Boolean(form.isSubmitting || store.isSubmitting)} />
        </Button>
    );
};
