'use client';

import React from 'react';
import { DISPLAY_DATE_FORMAT_LONG } from '@jucy-ui/common';
import { useFormState } from '@jucy-ui/forms';
import Box from '@mui/material/Box';
import { formControlClasses } from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { TripFieldsDateTimeContainer, TripFieldsSeparatorArrow } from '../../fields/TripFields/components';
import { SearchFormErrorMessage, SearchFormSubmitButton } from '../components';
import { useGetMaxDate, useGetMinDate, useSearchFormStore, useShouldDisableDate } from '../hooks';
import { jucySearchFormClasses } from '../lib/jucySearchFormClasses';
import { SearchFormBranchSelect } from './SearchFormBranchSelect';
import { SearchFormDateRangePicker } from './SearchFormDateRangePicker';
import { SearchFormDateTimeLocationContainer } from './SearchFormDateTimeLocationContainer';
import { SearchFormDriveAge } from './SearchFormDriveAge';
import { SearchFormDriveAgePromoCodeField } from './SearchFormDriveAgePromoCodeField';
import { SearchFormFleetTypeField } from './SearchFormFleetTypeField';
import { SearchFormPromoCode } from './SearchFormPromoCode';
import { SearchFormTimeSelect } from './SearchFormTimeSelect';

export const SearchFormFields: React.FC = () => {
    const store = useSearchFormStore();
    const form = useFormState();
    const submitError = typeof form.errors.root?.submit?.message === 'string' ? form.errors.root.submit.message : undefined;
    const horizontal = store.variant === 'horizontal';
    return (
        <>
            {store.hiddenFields?.fleetType ? null : (
                <SearchFormFleetTypeField
                    labels={{
                        motorhome: 'Star RV',
                    }}
                />
            )}
            <SearchFormDateRangePicker
                format={DISPLAY_DATE_FORMAT_LONG}
                minDate={useGetMinDate()}
                maxDate={useGetMaxDate()}
                disablePast={true}
                shouldDisableDate={useShouldDisableDate()}
                disabled={store.disabled}
                className={store.variant}
            >
                {({ startField, endField }) =>
                    horizontal ? (
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(6, 1fr)',
                                gridGap: '8px',
                            }}
                        >
                            {store.hiddenFields?.pickUpBranch ? null : <SearchFormBranchSelect name="pickUpBranch" disabled={store.disabled} />}
                            {store.hiddenFields?.pickUpDate ? null : startField}
                            {store.hiddenFields?.pickUpTime ? null : <SearchFormTimeSelect name="pickUpTime" disabled={store.disabled} />}
                            {store.hiddenFields?.dropOffBranch ? null : <SearchFormBranchSelect name="dropOffBranch" disabled={store.disabled} />}
                            {store.hiddenFields?.dropOffDate ? null : endField}
                            {store.hiddenFields?.dropOffTime ? null : <SearchFormTimeSelect name="dropOffTime" disabled={store.disabled} />}
                            {store.hiddenFields?.driverAge ? null : (
                                <SearchFormDriveAge
                                    disabled={store.disabled}
                                    formGroupOptions={{
                                        sx: {
                                            [`&.${formControlClasses.root}`]: {
                                                gridColumn: '4/4',
                                                marginBottom: 0,
                                            },
                                        },
                                    }}
                                />
                            )}
                            {store.hiddenFields?.promoCode ? null : (
                                <SearchFormPromoCode
                                    disabled={store.disabled}
                                    sx={{
                                        [`&.${formControlClasses.root}`]: {
                                            marginBottom: 0,
                                            gridColumn: '5/5',
                                        },
                                    }}
                                />
                            )}
                            <SearchFormSubmitButton sx={() => ({ gridColumn: '6/6' })} label={store.searchButtonLabel} disabled={store.disabled} />
                        </Box>
                    ) : (
                        <Stack
                            className={jucySearchFormClasses.dateTimeContainer}
                            gap={3}
                            direction={store.variant === 'small' ? 'column' : 'row'}
                            useFlexGap={true}
                            width="100%"
                        >
                            <SearchFormDateTimeLocationContainer position="start">
                                {store.hiddenFields?.pickUpBranch ? null : <SearchFormBranchSelect name="pickUpBranch" disabled={store.disabled} />}
                                <TripFieldsDateTimeContainer>
                                    {store.hiddenFields?.pickUpDate ? null : startField}
                                    {store.hiddenFields?.pickUpTime ? null : <SearchFormTimeSelect name="pickUpTime" disabled={store.disabled} />}
                                </TripFieldsDateTimeContainer>
                            </SearchFormDateTimeLocationContainer>
                            <TripFieldsSeparatorArrow variant={store.variant === 'small' ? 'vertical' : 'horizontal'} />
                            <SearchFormDateTimeLocationContainer position="end">
                                {store.hiddenFields?.dropOffBranch ? null : <SearchFormBranchSelect name="dropOffBranch" disabled={store.disabled} />}
                                <TripFieldsDateTimeContainer>
                                    {store.hiddenFields?.dropOffDate ? null : endField}
                                    {store.hiddenFields?.dropOffTime ? null : <SearchFormTimeSelect name="dropOffTime" disabled={store.disabled} />}
                                </TripFieldsDateTimeContainer>
                            </SearchFormDateTimeLocationContainer>
                        </Stack>
                    )
                }
            </SearchFormDateRangePicker>

            {!horizontal ? (
                <Grid
                    className={jucySearchFormClasses.actionsContainer}
                    container
                    spacing={store.variant === 'small' ? 1 : 3}
                    sx={{ mt: store.variant === 'small' ? 3 : 0, position: 'relative', zIndex: 0 }}
                >
                    <Grid xs={store.variant === 'small' ? 12 : 6} sx={{ pt: 0 }}>
                        {store.hiddenFields?.driverAge && store.hiddenFields.promoCode ? null : (
                            <SearchFormDriveAgePromoCodeField direction={store.variant === 'large' ? 'row' : 'column'} disabled={store.disabled} />
                        )}
                    </Grid>
                    <Grid xs={store.variant === 'small' ? 12 : 6} sx={{ display: 'flex', pt: 0 }}>
                        {store.variant === 'small' ? <SearchFormErrorMessage /> : null}
                        <Box sx={{ ml: 'auto', flex: '0 0 auto', py: 0 }}>
                            <SearchFormSubmitButton label={store.searchButtonLabel} disabled={store.disabled} />
                        </Box>
                    </Grid>
                    {store.variant === 'large' && submitError ? (
                        <Grid xs={12} sx={{ pt: 0 }}>
                            <SearchFormErrorMessage />
                        </Grid>
                    ) : null}
                </Grid>
            ) : null}
        </>
    );
};
