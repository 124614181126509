import { isServer } from './isServer';

type Func<T, Args> = (...args: Args[]) => T;

export const memoize = <T, Args>(func: Func<T, Args>): Func<T, Args> => {
    const cache = new Map<string, T>();

    if (isServer){
        return func
    }

    return (...args: Args[]): T => {
        const key = JSON.stringify(args);

        if (cache.has(key)) {
            return cache.get(key)!;
        }

        const result = func(...args);
        cache.set(key, result);
        return result;
    };
};
