'use client';

import React, { useCallback, useState } from 'react';
import { useIsMounted } from '@jucy-ui/common';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { StackProps } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

export interface QtyInputProps extends Omit<StackProps, 'onChange'> {
    value?: number | string;
    name?: string;
    min?: number | string;
    max?: number | string;
    onChange: (val: number, delta: -1 | 1) => void | Promise<void>;
    readonly?: boolean;
    disabled?: boolean;
    loading?: boolean;
    bundledQty?: number;
    error?: boolean;
    helperText?: string;
}

export const QtyInput = React.forwardRef<HTMLInputElement, QtyInputProps>(
    (
        { name, value = 0, loading: passedLoading, min = 0, max = 10, onChange, readonly, disabled, bundledQty = 0, error, helperText, ...props },
        ref
    ) => {
        const nValue = Number(value);
        const nMin = Number(min);
        const nMax = Number(max);
        const [qty, setQty] = useState(nValue);
        const [loading, setLoading] = useState(passedLoading);
        const [action, setAction] = useState<'increase' | 'decrease' | null>();
        const isMounted = useIsMounted();
        const updateQty = useCallback(
            async (val: number, delta: -1 | 1) => {
                setLoading(true);
                setQty(val);
                await onChange(val, delta);
                if (isMounted()) {
                    setLoading(false);
                }
            },
            [isMounted, onChange]
        );
        const decrease = useCallback(() => {
            if (qty - 1 < nMin) {
                return;
            }
            setAction('increase');
            return updateQty(qty - 1, -1);
        }, [nMin, qty, updateQty]);
        const increase = useCallback(() => {
            if (qty + 1 > nMax) {
                return;
            }
            setAction('decrease');
            return updateQty(qty + 1, 1);
        }, [nMax, qty, updateQty]);
        return (
            <Box sx={{ flex: '1 1 120px' }}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={(theme) => ({
                        border: '1px',
                        color: error ? theme.palette.error.main : 'inherit',
                        borderColor: error ? theme.palette.error.main : 'transparent',
                        borderStyle: 'solid',
                        borderRadius: theme.shape.borderRadius / 4,
                        p: 1,
                    })}
                    {...props}
                >
                    <IconButton
                        data-cy="decrease-btn"
                        aria-label="Decrease"
                        color="primary"
                        onClick={decrease}
                        disabled={qty <= nMin || readonly || disabled}
                        sx={{ border: 1, width: 42, height: 42 }}
                    >
                        {(loading || passedLoading) && action === 'increase' ? (
                            <CircularProgress sx={{ color: 'inherit' }} size={18} />
                        ) : (
                            <RemoveIcon fontSize="inherit" />
                        )}
                    </IconButton>
                    <Box data-cy="qty-field" className={'value'} minWidth={20} textAlign="center">
                        {qty + bundledQty}
                        <input type="hidden" name={name} value={qty + bundledQty} ref={ref} />
                    </Box>
                    <IconButton
                        data-cy="increase-btn"
                        aria-label="Increase"
                        color="primary"
                        onClick={increase}
                        disabled={qty + bundledQty >= nMax || readonly || disabled}
                        sx={{ border: 1, width: 42, height: 42 }}
                    >
                        {(loading || passedLoading) && action === 'decrease' ? (
                            <CircularProgress sx={{ color: 'inherit' }} size={18} />
                        ) : (
                            <AddIcon fontSize="inherit" />
                        )}
                    </IconButton>
                </Stack>
                {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
            </Box>
        );
    }
);
