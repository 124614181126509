import { DISPLAY_DATE_FORMAT_LONG, DISPLAY_TIME_FORMAT_SHORT, isValidDate } from '@jucy-ui/common';
import { format, isBefore } from 'date-fns';
import { getIsDateHolidayForBranch, getMinDate } from '../lib';
import { SearchFormData } from '../types';

export const validatePickUpDate = ({ pickUpBranch, pickUpDate, fleetType }: Partial<SearchFormData>): string | undefined => {
    if (!isValidDate(pickUpDate)) {
        return 'Please select valid pick up date';
    }

    if (pickUpBranch && getIsDateHolidayForBranch({ branch: pickUpBranch, date: pickUpDate })) {
        return 'Pick up branch is closed on this date';
    }

    const minPickUp = fleetType && getMinDate({ fleetType });
    if (minPickUp && isBefore(pickUpDate, minPickUp)) {
        return `Pick up date must be after ${format(minPickUp, `${DISPLAY_DATE_FORMAT_LONG} ${DISPLAY_TIME_FORMAT_SHORT}`)}`;
    }
};
