import { Deal, getNow, isValidDate, memoize } from '@jucy-ui/common';
import { addDays, addYears, min } from 'date-fns';
import { roundTime } from './roundTime';

export const getMaxDate = memoize(({ deal, pickUpDate }: { deal?: Deal; pickUpDate?: Date } = {}) => {
    const absMax = addYears(getNow(), 2);
    const dealEnd = deal?.conditions?.endDateOnOrBefore || deal?.conditions?.endDateBetween?.from;
    const dealMaxHireDaysDate = deal?.conditions?.maxHireDays && pickUpDate ? addDays(pickUpDate, deal.conditions.maxHireDays) : undefined;
    return roundTime(min([dealMaxHireDaysDate, dealEnd, absMax].filter((date) => isValidDate(date))));
});
