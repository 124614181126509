import { Deal, FleetType, HirePeriod, Site, getNow, isValidDate, setTimeFromString } from '@jucy-ui/common';
import { addDays } from 'date-fns';
import { getMinHireDays } from './getMinHireDays';
import { getServiceHours } from './getServiceHours';

export const getMinDropOff = ({
    pickUpDate = setTimeFromString(getNow(), '10:00'),
    pickUpBranch,
    dropOffBranch,
    fleetType,
    hirePeriods,
    deal,
}: {
    pickUpDate?: Date;
    pickUpBranch?: Site;
    dropOffBranch?: Site;
    fleetType?: FleetType;
    hirePeriods?: HirePeriod[];
    deal?: Deal;
}) => {
    const minHireDays = getMinHireDays({
        date: pickUpDate,
        pickUpBranch,
        dropOffBranch,
        fleetType,
        hirePeriods,
        deal,
    });
    const minDropOffDate = isValidDate(pickUpDate) && addDays(pickUpDate, minHireDays);
    const siteSettings = dropOffBranch && dropOffBranch.siteSettings.find((siteSetting) => siteSetting.fleetTypeId === fleetType?.id);
    const serviceHours = siteSettings && isValidDate(minDropOffDate) && getServiceHours({ date: minDropOffDate, siteSettings });

    if (serviceHours && !siteSettings.isAfterHoursDropOffAvailable) {
        // Only find the min drop off if out of hours drop off is unavailable
        return serviceHours.open; //Set min drop off to be service hours start
    }
    return minDropOffDate;
};
