import { deepSignal } from 'deepsignal/react';
import { SearchFormRepoData } from '../types';

export const searchFormRepoStore = deepSignal<SearchFormRepoData>({
    hirePeriods: [],
    fleetTypes: [],
    fleetCategories: [],
    regions: [],
    branches: [],
});
