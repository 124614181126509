import { useGetFleetTypes, useGetHirePeriods, useGetProductCatalog, useGetRegions } from '@jucy-ui/common';

export const usePrimeSearchFormCache = () => {
    useGetHirePeriods({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        notifyOnChangeProps: [],
    });
    useGetFleetTypes({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        notifyOnChangeProps: [],
    });
    useGetRegions({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        notifyOnChangeProps: [],
    });
    useGetProductCatalog({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        notifyOnChangeProps: [],
    });
};
