import { useCallback } from 'react';
import { searchFormRepoStore } from '../store/searchFormRepoStore';

export const useSearchFormData = () => {
    const fleetTypes = searchFormRepoStore.fleetTypes;
    const regions = searchFormRepoStore.regions;
    const fleetCategories = searchFormRepoStore.fleetCategories;
    return {
        getFleetType: useCallback((slug: string) => fleetTypes.find((fleetType) => fleetType.slug === slug), [fleetTypes]),
        getFleetCategory: useCallback(
            (fleetCategoryCode: string) => fleetCategories.find((fleetCategory) => fleetCategory.code === fleetCategoryCode),
            [fleetCategories]
        ),
        getSite: useCallback((siteCode: string) => regions.flatMap((r) => r.sites).find((site) => site.siteCode === siteCode), [regions]),
    };
};
