'use client';

import { batch } from '@preact/signals-react';
import { useQueries } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import {
    branchQueryKeys,
    fleetTypeQueryKeys,
    getFleetTypesWithPreloadedImages,
    getHirePeriods,
    getProductCatalog,
    getSites,
    hirePeriodQueryKeys,
    mapSitesToRegion,
    productCatalogQueryKeys,
} from '@jucy-ui/common';
import { searchFormRepoStore } from './searchFormRepoStore';

export const SearchFormDataProvider = ({ children }: { children: React.ReactNode }) => {
    const [
        { data: hirePeriods, error: hirePeriodsLoadError },
        { data: fleetTypes, error: fleetTypesLoadError },
        { data: branches, error: branchesLoadError },
        { data: fleetCategories, error: fleetCategoriesLoadError },
    ] = useQueries({
        queries: [
            { queryKey: hirePeriodQueryKeys.list(), queryFn: getHirePeriods, staleTime: Infinity },
            { queryKey: fleetTypeQueryKeys.list(), queryFn: getFleetTypesWithPreloadedImages, staleTime: Infinity },
            { queryKey: branchQueryKeys.list(), queryFn: getSites },
            { queryKey: productCatalogQueryKeys.list(), queryFn: getProductCatalog, staleTime: Infinity },
        ],
    });
    useEffect(() => {
        if (hirePeriods) {
            searchFormRepoStore.hirePeriods = hirePeriods;
        }
    }, [hirePeriods]);
    useEffect(() => {
        if (fleetTypes) {
            searchFormRepoStore.fleetTypes = fleetTypes;
        }
    }, [fleetTypes]);
    useEffect(() => {
        if (branches) {
            batch(() => {
                searchFormRepoStore.branches = branches;
                searchFormRepoStore.regions = mapSitesToRegion({ sites: branches });
            });
        }
    }, [branches]);
    useEffect(() => {
        if (fleetCategories) {
            searchFormRepoStore.fleetCategories = fleetCategories as unknown as typeof searchFormRepoStore.fleetCategories;
        }
    }, [fleetCategories]);

    if (hirePeriodsLoadError) {
        throw hirePeriodsLoadError;
    }
    if (fleetTypesLoadError) {
        throw fleetTypesLoadError;
    }
    if (branchesLoadError) {
        throw branchesLoadError;
    }
    if (fleetCategoriesLoadError) {
        throw fleetCategoriesLoadError;
    }
    return children;
};
