import { useComputed } from '@preact/signals-react';
import { useCallback } from 'react';
import { FleetType } from '@jucy-ui/common';
import { useBrand } from '../../context';
import { searchFormRepoStore } from '../store';
import { useSearchFormFieldValues } from './useSearchFormFieldValues';

const useResolveDealValidFleetTypes = () => {
    const { deal } = useSearchFormFieldValues();
    return useComputed(() => {
        const validFleetCategoryCodes = deal?.conditions?.productCodeOneOf || [];
        if (deal?.tags) {
            return new Set(deal.tags);
        }
        return validFleetCategoryCodes.length > 0
            ? new Set(
                  searchFormRepoStore?.fleetCategories
                      ?.filter((fleetCategory) => validFleetCategoryCodes.includes(fleetCategory.code))
                      .map((fleetCategory) => fleetCategory.fleetTypeSlug)
              )
            : null;
    }).value;
};

export const useShouldDisableFleetType = () => {
    const brand = useBrand();
    const dealFleetTypeSlugs = useResolveDealValidFleetTypes();
    return useCallback(
        (fleetType: FleetType) => {
            if (brand === 'star' && fleetType.slug !== 'motorhome') {
                return true;
            }
            if (dealFleetTypeSlugs) {
                return !dealFleetTypeSlugs.has(fleetType.slug);
            }

            return false;
        },
        [brand, dealFleetTypeSlugs]
    );
};
