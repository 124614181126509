import { type GetBookingRequest, type JucyCountryCode, type ListDealsRequest } from './models';

export const branchQueryKeys = {
    all: ['branch'],
    regions: () => [...branchQueryKeys.all, 'regions', 'list'],
    list: () => [...branchQueryKeys.all, 'list'],
    byCode: (code: string) => [...branchQueryKeys.all, 'code', code],
};

export const fleetTypeQueryKeys = {
    all: ['fleet-types'],
    list: () => [...fleetTypeQueryKeys.all, 'list'],
};

export const hirePeriodQueryKeys = {
    all: ['hire-periods'],
    list: () => [...hirePeriodQueryKeys.all, 'list'],
};
export const productCatalogQueryKeys = {
    all: ['product-catalog'],
    item: (code: string) => [...productCatalogQueryKeys.all, 'item', code],
    list: () => [...productCatalogQueryKeys.all, 'list'],
};
export const bookingQueryKeys = {
    all: ['booking'],
    entities: (reservationReference: string) => [...bookingQueryKeys.all, 'entities', reservationReference],
    options: (reservationReference: string) => [...bookingQueryKeys.all, 'options', reservationReference],
    get: (request: GetBookingRequest) => [
        ...bookingQueryKeys.all,
        'options',
        request.reservationReference,
        request.lastName || '',
        request.pickUpBranchCode || '',
    ],
};
export const countryQueryKeys = {
    all: ['countries'],
    list: () => [...countryQueryKeys.all, 'list'],
};
export const agentQueryKeys = {
    all: ['agent'],
    details: (accountId: string) => [...agentQueryKeys.all, 'details', accountId],
};

export const paymentConfig = {
    all: ['payment-config'],
    details: (countryCode?: JucyCountryCode) => [...paymentConfig.all, 'details', countryCode].filter(Boolean) as string[],
};

export const dealsQueryKeys = {
    all: ['deals'],
    list: ({ accountId, tags, region, code, type }: ListDealsRequest) => [
        ...dealsQueryKeys.all,
        'list',
        [accountId, tags?.size && tags.size > 0 ? Array.from(tags).join(',') : '', region, code, type].filter(Boolean).join('-'),
    ],
    get: ({ accountId, dealId }: { accountId?: string; dealId?: string }) => [...dealsQueryKeys.all, 'get', accountId, dealId].filter(Boolean) as string[],
};
